import {
    getHours24Energy,
    getIndoorEnvironment, getRealTimeEnergy
} from "@/api/xj";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchEnvironmentData(ctx, payload) {
            return new Promise((resolve, reject) => {
                getIndoorEnvironment()
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            })
        },
        fetchHours24Energy(ctx, payload) {
            return new Promise((resolve, reject) => {
                getHours24Energy()
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            })
        },
        fetchRealTimeEnergy(ctx, payload) {
            return new Promise((resolve, reject) => {
                getRealTimeEnergy({type: payload})
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            })
        },

    },
};
