<template>
  <div>
    <label>图表类型（type）</label>
    <b-form-group>
      <v-select
        v-model="option.type"
        :clearable="false"
        :options="type_list"
        :reduce="(text) => text.value"
        class="rounded"
        label="text"
      ></v-select>
    </b-form-group>

    <label>来源节点数据字段（sourceField）</label>
    <b-form-group>
      <b-form-input v-model="option.sourceField" />
    </b-form-group>

    <label>目标节点数据字段（targetField）</label>
    <b-form-group>
      <b-form-input v-model="option.targetField" />
    </b-form-group>

    <label>节点之间关系的权重字段（weightField）</label>
    <b-form-group>
      <b-form-input v-model="option.targetField" />
    </b-form-group>

  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { reactive, toRefs } from "@vue/composition-api";
import vSelect from "vue-select";

export default {
  name: "G2RelationSettings",
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: ["item"],
  setup(props) {
    const { item } = props;
    const event = reactive({
      option: {
        type: "桑基图",
        sourceField: "",
        targetField: "",
        weightField: "",
        nodeWidthRatio: "",
        nodePaddingRatio: "",
      },
      type_list: [
        { value: "sankey", text: "桑基图" },
        { value: "chord", text: "弦图" },
      ],
    });
    // watch(() => event.selectedFile, (file) => {
    // 	if (!!file) {
    // 		uploadFile(file)
    // 	}
    // })
    //更新store的数据
    // const update_store_options=()=>{
    // 	let options = store.state['panel-group']['panel-modal']['options']
    // 	options.list = event.list
    // 	store.commit('panel-group/UPDATE_PANEL_MODAL_OPTIONS', { ...options })
    // }
    return {
      ...toRefs(event),
    };
  },
};
</script>
