<template>
  <div>
    <b-form>
      <label>X轴对应的字段名（xField）</label>
	  <b-form-group>
		<v-select></v-select>
	  </b-form-group>

	  <label>Y轴对应的字段名（yField）</label>
	  <b-form-group>
		<v-select></v-select>
	  </b-form-group>

	  <label>生成热力图颜色的字段名（colorField）</label>
	  <b-form-group>
		<v-select></v-select>
	  </b-form-group>

	  <label>配置图表数据元信息的字段名（field）</label>
	  <b-form-group>
		<v-select></v-select>
	  </b-form-group>

    </b-form>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { reactive, toRefs, onMounted ,watch} from "@vue/composition-api";
import vSelect from "vue-select";

export default {
  name: "G2HeatmapSettings",
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: ["item"],
  setup(props) {
    const { item } = props;
    const event = reactive({
		option:item.options,
	});
    // watch(() => event.selectedFile, (file) => {
    // 	if (!!file) {
    // 		uploadFile(file)
    // 	}
    // })
    //更新store的数据
    // const update_store_options=()=>{
    // 	let options = store.state['panel-group']['panel-modal']['options']
    // 	options.list = event.list
    // 	store.commit('panel-group/UPDATE_PANEL_MODAL_OPTIONS', { ...options })
    // }

	onMounted(()=>{
		console.log("热力图",item.options)
		console.log("热力图",item)
	})

    return {
      ...toRefs(event),
    };
  },
};
</script>
