export default [
    {
        path: "/:project_id/cabr/demo1",
        name: "project-cabr-demo1",
        component: () => import("@/views/front/cabr/demo1"),
    },
    {
        path: "/:project_id/cabr/dashboard",
        name: "project-cabr-dashboard",
        component: () => import("@/views/front/cabr/dashboard"),
    },
    {
        path: "/:project_id/cabr/dashboard-around",
        name: "project-cabr-dashboard",
        component: () => import("@/views/front/cabr/dashboard/index-around"),
    },
    {
        path: "/:project_id/cabr/dashboard_back",
        name: "project-cabr-dashboard",
        component: () => import("@/views/front/cabr/dashboard/index.back.vue"),
    },
    {
        path: "/phyMonitor",
        name: "FunMenu_PhyMonitor",
        component: () => import("@/views/front/common/agent-list/components/FunMenu/PhyMonitor"),
    },
    {
        path: "/relationGraph",
        name: "FunMenu_relationGraph",
        component: () => import("@/views/front/common/agent-list/components/FunMenu/relationGraph"),
    },
    {
        path: "/AgentList",
        name: "FunMenu_AgentList",
        component: () => import("@/views/front/common/agent-list/AgentList"),
    },
    {
        path: "/AgentResult",
        name: "AgentResult",
        component: () => import("@/views/front/common/agent-list/AgentResult"),
    },
    {
        path: "/AgentDetail/:id",
        name: "AgentDetail",
        component: () => import("@/views/front/common/agent-list/AgentDetail"),
    },
    {
        path: "/diagnosis/general",
        name: "DiagnosisGeneral",
        component: () => import("@/views/front/cabr/diagnosis/general/index.vue"),
    },
    {
        path: "/diagnosis/speciality",
        name: "DiagnosisSpeciality",
        component: () => import("@/views/front/cabr/diagnosis/speciality/index.vue"),
    },
    {
        path: "/AgentDetail2/:id",
        name: "AgentDetail2",
        component: () => import("@/views/front/common/agent-list/AgentDetail2"),
    },
    {
        path: "/AgentDetail2/:id",
        name: "index-diagnosis-risk",
        component: () => import("@/views/front/common/agent-list/AgentDetail2"),
    }
];
