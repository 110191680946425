<template>
	<div id="embed-settings">
		<b-form>
			<b-row>
				<!-- first name -->
				<b-col cols="12">
					<b-form-group label="链接网址">
						<b-form-textarea v-model="src" rows="4" />
					</b-form-group>
				</b-col>

				<!-- checkbox -->
				<b-col cols="12">
					<b-form-group label="链接类型">
						<b-form-radio-group v-model="type" :options="type_options" class="display-inline" name="radio-inline" />
					</b-form-group>
				</b-col>
			</b-row>
		</b-form>
	</div>
</template>

<script>
	import store from "@/store";
	import Ripple from "vue-ripple-directive";
	import { reactive, toRefs, watch } from "@vue/composition-api";

	export default {
		name: "EmbedSettings",
		directives: {
			Ripple,
		},
		props: ["item"],
		setup(props) {
			const { item } = props;
			const event = reactive({
				type_options: [
					{
						text: "普通链接",
						value: "embed",
					},
					{
						text: "VR全景",
						value: "vr",
					},
					{
						text: "BIM模型",
						value: "bim",
					},
				],
				type: item.options.type,
				src: item.options.src,
			});
			watch([() => event.type, () => event.src], (value) => {
				if (!!value) {
					const options = {
						type: event.type,
						src: event.src,
					};
					store.commit("panel-group/UPDATE_PANEL_MODAL_OPTIONS", { ...options });
				}
			});
			return {
				...toRefs(event),
			};
		},
	};
</script>
