<template>
  <div class="h-100">
    <div class="h-100">
      <label>图表类型（type）</label>
      <b-form-group>
        <v-select v-model="option.plots[0].type" :clearable="false" :options="chart_type_list"
                  :reduce="(text) => text.value" class="rounded" label="text" placeholder="请选择图表类型"
                  @input="change_chart_type"/>
      </b-form-group>
      <template v-if="['pie'].includes(option.plots[0].type)">
        <label>数值的字段名（angleField）</label>
        <b-form-group>
          <v-select v-model="option.plots[0].options.angleField" :clearable="false" :options="query_data.fields"
                    class="rounded" placeholder="请选择数值的字段名"/>
        </b-form-group>

        <label>区分类别的字段名（colorField）</label>
        <b-form-group>
          <v-select v-model="option.plots[0].options.colorField" :clearable="false" :options="query_data.fields"
                    class="rounded" placeholder="请选择区分类别的字段名"/>
        </b-form-group>

        <label>环形内径（innerRadius）</label>
        <b-form-group>
          <b-form-input v-model="option.plots[0].options.innerRadius" class="rounded" placeholder="请输入环形内径（0~1）"
                        type="number"/>
        </b-form-group>
      </template>
      <hr class="my-2"/>
      <template v-if="['line', 'column', 'area', 'bar', 'scatter'].includes(option.plots[0].type)">
        <h6>X轴配置</h6>
        <b-row>
          <b-col cols="6">
            <label>X轴对应的字段名（xField）</label>
            <b-form-group>
              <v-select v-model="option.plots[0].options.xField" :clearable="false" :options="query_data.fields"
                        class="rounded" placeholder="请选择x轴映射的数据字段"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <label>X轴标题</label>
            <b-form-group>
              <b-form-input v-model="option.plots[0].options.xAxis.title.text" class="rounded" placeholder="X轴标题"/>
            </b-form-group>
          </b-col>
        </b-row>
        <template v-if="['column'].includes(option.plots[0].type)">
          <b-row class="mt-50">
            <b-col cols="4">
              <label>X轴最小值限制（不填则自动配置）</label>
              <b-form-group>
                <b-form-input v-model="option.plots[0].options.xAxis.minLimit" :formatter="emptyFormatter"
                              class="rounded" placeholder="Y轴最小值限制" type="number"/>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <label>X轴最大值限制（不填则自动配置）</label>
              <b-form-group>
                <b-form-input v-model="option.plots[0].options.xAxis.maxLimit" :formatter="emptyFormatter"
                              class="rounded" placeholder="Y轴最大值限制" type="number"/>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <label>X轴刻度间隔（不填则自动配置）</label>
              <b-form-group>
                <b-form-input v-model="option.plots[0].options.xAxis.tickInterval" :formatter="emptyFormatter"
                              class="rounded" placeholder="Y轴刻度间隔" type="number"/>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <hr class="my-2"/>
        <h6>Y轴配置</h6>
        <b-row>
          <b-col cols="6">
            <label>Y轴对应的字段名（yField）</label>
            <b-form-group>
              <v-select v-model="option.plots[0].options.yField" :clearable="false" :options="query_data.fields"
                        class="rounded" placeholder="请选择y轴映射的数据字段"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <label>Y轴标题</label>
            <b-form-group>
              <b-form-input v-model="option.plots[0].options.yAxis.title.text" class="rounded" placeholder="Y轴标题"/>
            </b-form-group>
          </b-col>
        </b-row>

        <template v-if="['line', 'area', 'bar', 'scatter'].includes(option.plots[0].type)">
          <b-row class="mt-50">
            <b-col cols="4">
              <label>Y轴最小值限制（不填则自动配置）</label>
              <b-form-group>
                <b-form-input v-model="option.plots[0].options.yAxis.minLimit" :formatter="emptyFormatter"
                              class="rounded" placeholder="Y轴最小值限制" type="number"/>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <label>Y轴最大值限制（不填则自动配置）</label>
              <b-form-group>
                <b-form-input v-model="option.plots[0].options.yAxis.maxLimit" :formatter="emptyFormatter"
                              class="rounded" placeholder="Y轴最大值限制" type="number"/>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <label>Y轴刻度间隔（不填则自动配置）</label>
              <b-form-group>
                <b-form-input v-model="option.plots[0].options.yAxis.tickInterval" :formatter="emptyFormatter"
                              class="rounded" placeholder="Y轴刻度间隔" type="number"/>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <hr class="my-2"/>
        <h6>区分类别配置</h6>
        <template v-if="['line', 'column', 'area', 'bar'].includes(option.plots[0].type)">
          <label>区分类别的字段名（seriesField）</label>
          <b-form-group>
            <v-select v-model="option.plots[0].options.seriesField" :clearable="false" :options="query_data.fields"
                      class="rounded" placeholder="请选择区分类别的字段名"/>
          </b-form-group>
        </template>
        <template v-if="['scatter'].includes(option.plots[0].type)">
          <b-form-group>
            <label>区分类别的字段名（colorField）</label>
            <v-select v-model="option.plots[0].options.colorField" :clearable="false" :options="query_data.fields"
                      class="rounded" placeholder="请选择区分类别的字段名"/>
          </b-form-group>
        </template>

        <template>
          <hr class="my-2"/>
          <b-row>
            <b-col v-if="['column', 'bar'].includes(option.plots[0].type)" cols="12">
              <label>是否分组（isGroup）</label>
              <b-form-group>
                <b-form-checkbox :checked="option.plots[0].options.isGroup" inline name="check-button" switch
                                 @change="option.plots[0].options.isGroup = !option.plots[0].options.isGroup">
                  {{ option.plots[0].options.isGroup ? "" : "不" }}分组
                </b-form-checkbox>
              </b-form-group>
              <b-form-group v-show="option.plots[0].options.isGroup">
                <label>用于分组的字段名（groupField）</label>
                <v-select v-model="option.plots[0].options.groupField" :clearable="false" :options="query_data.fields"
                          class="rounded" placeholder="请选择用于分组的字段名"/>
              </b-form-group>
            </b-col>
            <b-col v-if="['line', 'column', 'area', 'bar'].includes(option.plots[0].type)" cols="4" xs="6">
              <label>是否堆叠（isStack）</label>
              <b-form-group>
                <b-form-checkbox :checked="option.plots[0].options.isStack" inline name="check-button" switch
                                 @change="option.plots[0].options.isStack = !option.plots[0].options.isStack">
                  {{ option.plots[0].options.isStack ? "" : "不" }}堆叠
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="4" xs="6">
              <label>是否显示图例</label>
              <b-form-group>
                <b-form-checkbox :checked="!(option.legend === false)" inline name="check-button" switch
                                 @change="option.legend = !option.legend"> {{
                    !(option.legend === false) ? "" : "不"
                  }}显示
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col v-if="['line', 'column', 'area', 'bar', 'scatter'].includes(option.plots[0].type)" cols="4" xs="6">
              <label>是否显示时间轴</label>
              <b-form-group>
                <b-form-checkbox :checked="!(option.slider === false)" inline name="check-button" switch
                                 @change="option.slider = !option.slider"> {{
                    !(option.slider === false) ? "" : "不"
                  }}显示
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </template>
      </template>
    </div>
    <!--		<div>-->
    <!--			<hr/>-->
    <!--			<h6>参考文档：</h6>-->
    <!--			<b-list-group flush>-->
    <!--				<b-list-group-item-->
    <!--					href="https://g2plot.antv.vision/zh/docs/api/plots/column#%E6%95%B0%E6%8D%AE%E6%98%A0%E5%B0%84"-->
    <!--				>-->
    <!--					配置文档：<small>数据映射相关参数</small>-->
    <!--				</b-list-group-item>-->
    <!--				<b-list-group-item-->
    <!--					href="https://g2plot.antv.vision/zh/docs/api/plots/column#%E5%9B%BE%E5%BD%A2%E6%A0%B7%E5%BC%8F"-->
    <!--					target="_blank"-->
    <!--				>-->
    <!--					配置文档：<small>图形样式相关参数</small>-->
    <!--				</b-list-group-item>-->
    <!--				<b-list-group-item-->
    <!--					href="https://g2plot.antv.vision/zh/docs/api/plots/column#%E5%9B%BE%E8%A1%A8%E7%BB%84%E4%BB%B6"-->
    <!--					target="_blank"-->
    <!--				>-->
    <!--					配置文档：<small>图表组件相关参数</small>-->
    <!--				</b-list-group-item>-->
    <!--				<b-list-group-item-->
    <!--					href="https://g2plot.antv.vision/zh/docs/api/plots/column#%E5%9B%BE%E8%A1%A8%E4%BA%A4%E4%BA%92"-->
    <!--					target="_blank"-->
    <!--				>-->
    <!--					配置文档：<small>数据映射相关参数</small>-->
    <!--				</b-list-group-item>-->
    <!--			</b-list-group>-->
    <!--		</div>-->
  </div>
</template>

<script>
import {computed, onMounted, reactive, toRefs, watch} from "@vue/composition-api";

import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";

export default {
  name: "G2-Mix-Settings",
  directives: {
    Ripple,
  },
  components: {
    vSelect,
  },
  props: ["item"],
  setup(props) {
    const {item} = props;
    const query_key = "query_" + item.query_id;
    // ------------------------------------------------
    // 引入页面数据
    // ------------------------------------------------
    let event = reactive({
      panel_modal: computed(() => {
        return item;
      }),
      option: item.options,
      query_data: computed(() => {
        let query_data = store.state["panel-group"]["query-data-list"][query_key];
        if (!(query_data && query_data.temp_data)) {
          query_data = Object.assign(
              {},
              {
                temp_data: [],
                fields: [],
              }
          );
        }
        console.log("query_data: ", query_data);
        return query_data;
      }),
      chart_type_list: [
        {text: "折线图 Line", value: "line"},
        {text: "柱状图 Column", value: "column"},
        {text: "面积图 Area", value: "area"},
        {text: "散点图 Scatter", value: "scatter"},
        {text: "条形图 Bar", value: "bar"},
        {text: "饼图/环图 Pie", value: "pie"},
      ],
    });

    watch(
        () => event.option.plots[0].type,
        (type) => {
          options_handle(type);
          // event.option.plots[0].options = Object.assign(new_options,old_options);
        }
    );

    onMounted(() => {
      options_handle(event.option.plots[0].type);
    });

    const activeYMinLimit = () => {
      let cur_value = event.option.plots[0].options.yAxis.minLimit;
      if (cur_value !== null) {
        console.log("change1", cur_value);
        event.option.plots[0].options.yAxis.minLimit = null;
      } else {
        console.log("change2");
        event.option.plots[0].options.yAxis.minLimit = 0;
      }
    };

    const options_handle = (type) => {
      let new_options = {};
      const old_options = JSON.parse(JSON.stringify(event.option.plots[0].options));
      if (["line", "area"].includes(type)) {
        new_options = line_options;
      }
      if (["column", "bar"].includes(type)) {
        new_options = column_options;
      }
      if (["scatter"].includes(type)) {
        new_options = scatter_options;
      }
      if (["pie"].includes(type)) {
        new_options = pie_options;
      }
      const new_options_keys = Object.keys(new_options);
      for (let i in old_options) {
        if (new_options_keys.includes(i)) {
          new_options[i] = old_options[i];
        }
      }
      event.option.plots[0].options = new_options;
    };

    //折线图配置项
    const line_options = {
      xField: "",
      yField: "",
      xAxis: {
        title: {
          text: "",
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        minLimit: null,
        maxLimit: null,
        tickInterval: null,
      },
      seriesField: "",
      isStack: false,
      meta: {},
      annotations: [],
    };
    //柱状图图配置项
    const column_options = {
      xField: "",
      yField: "",
      xAxis: {
        title: {
          text: "",
        },
        minLimit: null,
        maxLimit: null,
        tickInterval: null,
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      seriesField: "",
      isStack: false,
      isGroup: false,
      groupField: "",
      meta: {},
      annotations: [],
    };
    //散点图配置项
    const scatter_options = {
      xField: "",
      yField: "",
      xAxis: {
        title: {
          text: "",
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        minLimit: null,
        maxLimit: null,
        tickInterval: null,
      },
      colorField: "",
      // sizeField:"",
      // shapeField:"",
      isGroup: false,
      groupField: "",
      meta: {},
      annotations: [],
    };
    //饼图/环图配置项
    const pie_options = {
      angleField: "",
      colorField: "",
      innerRadius: 0,
    };

    //切换图表类型的数据处理
    const change_chart_type = (type1, type2) => {
      console.log(type1, type2);
    };
    //更新store的数据
    const update_store_options = () => {
      let options = store.state["panel-group"]["panel-modal"]["options"];
      options.option = event.option;
      store.commit("panel-group/UPDATE_PANEL_MODAL_OPTIONS", {
        ...options,
      });
    };

    const emptyFormatter = (value) => {
      let result = null;
      if (value !== "") result = value;
      console.log("result", result);
      return result;
    };

    return {
      ...toRefs(event),
      change_chart_type,
      emptyFormatter,
    };
  },
};
</script>
<style></style>
