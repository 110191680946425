<template>
  <div>
    <statistic-card-with-area-chart
        v-if="option.type === 'area-chart'"
        :chart-data="option.data"
        :color="option.color"
        :icon="option.icon"
        :statistic="option.title"
        :statistic-title="option.text"
        class="h-100"
    />
    <statistic-card-with-line-chart
        v-else-if="option.type === 'line-chart'"
        :chart-data="option.data"
        :color="option.color"
        :icon="option.icon"
        :statistic="option.title"
        :statistic-title="option.text"
        class="h-100"
    />
    <statistic-card-horizontal
        v-else-if="option.type === 'horizontal'"
        :color="option.color"
        :icon="option.icon"
        :statistic="option.title"
        :statistic-title="option.text"
        class="h-100"
    />
    <statistic-card-vertical
        v-else
        :color="option.color"
        :icon="option.icon"
        :statistic="option.title"
        :statistic-title="option.text"
        class="h-100"
    />
  </div>
</template>

<script>
import store from "@/store";
import {computed, onMounted, reactive, toRefs, watch} from "@vue/composition-api";
import {kFormatter} from "@/@core/utils/filter";

export default {
  components: {
    StatisticCardVertical: () =>
        import(
            "@/@core/components/statistics-cards/StatisticCardVertical.vue"
            ),
    StatisticCardHorizontal: () =>
        import(
            "@/@core/components/statistics-cards/StatisticCardHorizontal.vue"
            ),
    StatisticCardWithAreaChart: () =>
        import(
            "@/@core/components/statistics-cards/StatisticCardWithAreaChart.vue"
            ),
    StatisticCardWithLineChart: () =>
        import(
            "@/@core/components/statistics-cards/StatisticCardWithLineChart.vue"
            ),
  },
  props: ["item"],
  setup(props) {
    const {item} = props;
    const query_key = "query_" + item.query_id;
    const event = reactive({
      option: computed(() => {
        return item.options;
      }),
      query_data: computed(() => {
        let query_data =
            store.state["panel-group"]["query-data-list"][query_key];
        if (!(query_data && query_data.temp_data)) {
          query_data = Object.assign({}, {temp_data: []});
        }
        return query_data;
      }),
    });
    watch(
        [
          () => event.query_data.temp_data,
        ],
        () => {
          algorithm_result();
        },
        "deep"
    );
    const algorithm_result = () => {
      const old_title = event.option.title;
      const old_data = event.option.data;
      const field = event.option.field;
      const algorithm = event.option.algorithm;
      const data_list = event.query_data.temp_data.map((data) =>
          parseFloat(!!data[field] ? data[field] : 0)
      );

      event.option.data = [
        {
          data: [...data_list],
          name: "",
        },
      ];

      const length = data_list.length;
      let result = 0;
      if (algorithm === "latest") {
        result = data_list[length - 1];
      } else if (algorithm === "max") {
        result = Math.max(...data_list);
      } else if (algorithm === "min") {
        result = Math.min(...data_list);
      } else if (algorithm === "middle") {
        let arr = data_list;
        arr = arr.sort();
        //三目运算+IIFE写法
        length % 2 === 1
            ? (() => {
              result = arr[(length - 1) / 2];
            })()
            : (() => {
              result =
                  (arr[length / 2] + arr[length / 2 - 1]) / 2;
            })();
      } else {
        const sum = eval(data_list.join("+"));
        if (algorithm === "sum") {
          result = sum;
        }
        if (algorithm === "avg") {
          result = length > 0 ? parseFloat(sum / length) : 0;
        }
      }
      event.option.title = result?.toFixed(2) || 0;
    };
    onMounted(() => {
      algorithm_result();
    })
    return {
      ...toRefs(event),
      kFormatter,
    };
  },
};
</script>
