import axios from "@/libs/axios";
import COS from "cos-js-sdk-v5";
import { customAlphabet, urlAlphabet } from "nanoid";
import { showToast } from "@/libs/utils/showToast";

const Bucket = "cabrenergy-v2-1255710621";
const Region = "ap-beijing";

/**
 * 获取cos临时key
 * @returns {COS}
 */
const cos_init = async () => {
	return new COS({
		getAuthorization: function (options, callback) {
			axios.get("/api/cos_temp_key").then((response) => {
				let data = response.data;
				let credentials = data && data.credentials;
				if (!data || !credentials) return console.error("credentials invalid");
				callback({
					TmpSecretId: credentials.tmpSecretId,
					TmpSecretKey: credentials.tmpSecretKey,
					SecurityToken: credentials.sessionToken,
					StartTime: data.startTime,
					ExpiredTime: data.expiredTime,
				});
			});
		},
	});
};

/**
 * 文件列表
 * @param Prefix
 * @param callback
 * @returns {Promise<void>}
 */
export const cos_list = async (Prefix, callback) => {
	const cos = await cos_init();
	cos.getBucket(
		{
			Bucket /* 必须 */,
			Region /* 存储桶所在地域，必须字段 */,
			Prefix /* 非必须 */,
		},
		(err, data) => {
			if (err) {
				showToast(null, "错误：删除失败！", err.message);
				return;
			}
			const folder = data.CommonPrefixes.map((item) => {
				return {
					Prefix: item.Prefix,
					name: item.Prefix.replace(Prefix, "").slice(0, -1),
					isDir: true,
				};
			});
			const files = data.Contents.filter((item) => !item.Key.endsWith("/")).map((item) => {
				return {
					...item,
					name: item.Key.replace(Prefix, ""),
				};
			});
			callback(folder.concat(files));
		}
	);
};

/**
 * 文件上传
 * @param file
 * @param folder
 * @param onProgress
 * @param callback
 * @returns {Promise<void>}
 */
export const cos_upload = async (file, onProgress, callback, folder = "uploads") => {
	const cos = await cos_init();
	const suffix = file.name.split(".").pop().toLowerCase();
	const nanoid = customAlphabet(urlAlphabet, 15);
	const nano_name = nanoid();
	const filename = nano_name + "." + suffix;
	const filepath = folder + "/" + filename;
	cos.putObject(
		{
			Bucket,
			Region,
			Key: filepath,
			StorageClass: "STANDARD",
			Body: file,
			onProgress: (progressData) => {
				onProgress(progressData);
			},
		},
		function (err, data) {
			if (err) {
				showToast(null, "错误：上传失败！", err.message);
				return;
			}
			callback({
				...data,
				filename,
				filepath,
			});
		}
	);
};

/**
 * 文件下载
 * @param filename
 * @returns {Promise<void>}
 */
export const cos_download = async (filename) => {
	const cos = await cos_init();
	cos.getObjectUrl(
		{
			Bucket,
			Region,
			Key: filename,
		},
		(err, data) => {
			if (err) {
				showToast(null, "错误：下载失败！", err.message);
				return;
			}
			const url = data.Url + (data.Url.indexOf("?") > -1 ? "&" : "?") + "response-content-disposition=attachment"; // 补充强制下载的参数
			// 使用iframe下载
			const elemIF = document.createElement("iframe");
			elemIF.src = url;
			elemIF.style.display = "none";
			document.body.appendChild(elemIF);
		}
	);
};

/**
 * 文件删除
 * @param filename
 * @param callback
 * @returns {Promise<void>}
 */
export const cos_delete = async (filename, callback) => {
	const cos = await cos_init();
	cos.deleteObject(
		{
			Bucket,
			Region,
			Key: filename,
		},
		(err, data) => {
			if (err) {
				showToast(null, "错误：删除失败！", err.message);
				return;
			}
			callback(data);
		}
	);
};
