<template>
  <div class="w-100 h-100 position-relative">
    <!-- <h5 class="text-center text-danger" style="margin-top: 10%">热力图</h5> -->
    <div id="heatmapView" style="width: 90%; height: 90%"></div>
  </div>
</template>

<script>
import { Heatmap } from "@antv/g2plot";
import { onMounted, ref } from "@vue/composition-api";
import DataJson from "./data";
export default {
  name: "G2HeatmapView",
  setup() {
    const initHeatmapPlot = () => {
      const heatmapPlot = new Heatmap(document.getElementById("heatmapView"), {
        autoFit: false,
        data:DataJson,
        xField: "Month of Year",
        yField: "District",
        colorField: "AQHI",
        color: ["#174c83", "#7eb6d4", "#efefeb", "#efa759", "#9b4d16"],
        meta: {
          "Month of Year": {
            type: "cat",
          },
        },
      });
      heatmapPlot.render();
    };

	onMounted(()=>{
		initHeatmapPlot()
	})
  },
};
</script>
