<template>
  <div>
    <b-form>
      <label>数据映射（percent）</label>
      <b-form-group>
        <b-form-input v-model="option.percent" />
      </b-form-group>

      <label>形状（shape）</label>
      <b-form-group>
        <v-select
          v-model="option.shape"
          :clearable="false"
          :options="params_shape_list"
          :reduce="(text) => text.value"
          class="rounded"
          label="text"
        ></v-select>
      </b-form-group>

      <label>外容器宽度（border）</label>
      <b-form-group>
        <b-form-input v-model="option.border" />
      </b-form-group>

      <label>外框容器和内部波形的间距（distance）</label>
      <b-form-group>
        <b-form-input v-model="option.distance" />
      </b-form-group>

      <label>水波的波长度（length）</label>
      <b-form-group>
        <b-form-input v-model="option.length" />
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { reactive, toRefs } from "@vue/composition-api";
import vSelect from "vue-select";

export default {
  name: "G2ProgressWaveSettings",
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: ["item"],
  setup(props) {
    const { item } = props;
    const event = reactive({
      option: {
        percent: "0.25",
        shape: "圆形",
        border:"1",
        distance:"0",
        length:'192'
      },
      params_shape_list: [
        { value: "circle", text: "圆形" },
        { value: "diamond ", text: "钻石形" },
        { value: "rect ", text: "矩形" },
        { value: "triangle  ", text: "三角形" },
        { value: "pin  ", text: "水滴型" },
      ],
    });
    // watch(() => event.selectedFile, (file) => {
    // 	if (!!file) {
    // 		uploadFile(file)
    // 	}
    // })
    //更新store的数据
    // const update_store_options=()=>{
    // 	let options = store.state['panel-group']['panel-modal']['options']
    // 	options.list = event.list
    // 	store.commit('panel-group/UPDATE_PANEL_MODAL_OPTIONS', { ...options })
    // }
    return {
      ...toRefs(event),
    };
  },
};
</script>
