<template>
  <div class="w-100 h-100 position-relative">
    <!-- <h5 class="text-center text-danger" style="margin-top: 10%">进度图-环形</h5> -->
    <div id="ringView"></div>
  </div>
</template>

<script>
import { RingProgress } from "@antv/g2plot";
import { onMounted } from "@vue/composition-api";
export default {
  name: "G2ProgressRingView",
  setup() {
    const initRingProgress = () => {
      const ringProgress = new RingProgress("ringView", {
        height: 150,
        width: 300,
        autoFit: false,
        percent: 0.6,
        color: ["#F4664A", "#E8EDF3"],
        innerRadius: 0.85,
        radius: 0.98,
        statistic: {
          title: {
            style: { color: "#363636", fontSize: "12px", lineHeight: "14px" },
            formatter: () => "进度",
          },
        },
      });

      ringProgress.render();
    };

	onMounted(()=>{
		initRingProgress()
	})
  },
};
</script>
