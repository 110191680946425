<template>
  <div class="w-100 h-100 position-relative">
    <!-- <h5 class="text-center text-danger" style="margin-top: 10%">进度图-水波</h5> -->
    <div id="waveView" style="width: 90%; height: 90%"></div>
  </div>
</template>

<script>
import { Liquid } from "@antv/g2plot";
import { onMounted } from "@vue/composition-api";
export default {
  name: "G2ProgressWaveView",
  setup() {
    const initLiquidPlot = () => {
        const liquidPlot = new Liquid("waveView", {
          percent: 0.25,
          outline: {
            border: 1,
            distance: 0,
          },
          wave: {
            length: 128,
          },
        });

    //   const liquidPlot = new Liquid(
    //     "waveView",
    //     {
    //       theme: {
    //         colors10: Object.values($themeColors),
    //       },
    //       color: () => {
    //         return $themeColors.primary;
    //       },
    //       percent: 0.56,
    //       radius: 1,
    //     }
    //   );
      liquidPlot.render();
    };

    onMounted(() => {
      console.log("水波纹组件")
      initLiquidPlot();
    });
  },
};
</script>
