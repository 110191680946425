export default [//锦什坊项目主页
    {
        path: "/:project_id/scds/home-page",
        name: "project-scds-homepage",
        component: () => import("@/views/front/scds/homepage"),
    }, //锦什坊项目子系统
    {
        path: "/:project_id/scds/subsystems/:system_key",
        name: "project-scds-subsystems",
        component: () => import("@/views/front/scds/subsystems"),
    },
    {
        path: "/:project_id/scds/calibrationWater",
        name: "project-scds-calibration-water",
        component: () => import("@/views/front/scds/subsystems/components/energy-management/components/calibration-water/index.vue"),
    },
    {
        path: "/:project_id/scds/calibrationWater",
        name: "project-scds-calibration-elec",
        component: () => import("@/views/front/scds/subsystems/components/energy-management/components/calibration-elec/index.vue"),
    },
    {
        path: "/:project_id/scds/trendElec",
        name: "project-scds-trend-elec",
        component: () => import("@/views/front/scds/subsystems/components/energy-management/components/trend-elec/index.vue"),
    },
    {
        path: "/:project_id/scds/trendWater",
        name: "project-scds-trend-water",
        component: () => import("@/views/front/scds/subsystems/components/energy-management/components/trend-water/index.vue"),
    },
    {
        path: "/:project_id/scds/rankingElec",
        name: "project-scds-ranking-elec",
        component: () => import("@/views/front/scds/subsystems/components/energy-management/components/ranking-elec/index.vue"),
    },
    {
        path: "/:project_id/scds/rankingElecPosition",
        name: "project-scds-ranking-elec-position",
        component: () => import("@/views/front/scds/subsystems/components/energy-management/components/ranking-elec-position/index.vue"),
    },
    {
        path: "/:project_id/scds/quotasElec",
        name: "project-scds-quotas-elec",
        component: () => import("@/views/front/scds/subsystems/components/energy-management/components/quotas-elec/index.vue"),
    },
    {
        path: "/:project_id/scds/quotasWater",
        name: "project-scds-quotas-water",
        component: () => import("@/views/front/scds/subsystems/components/energy-management/components/quotas-water/index.vue"),
    },
    {
        path: "/:project_id/scds/engineering-center",
        name: "project-scds-engineering-center",
        component: () => import("@/views/front/jsf35/operations/engineering-center"),
    },
    {
        path: "/:project_id/scds/operations-center",
        name: "project-scds-operations-center",
        component: () => import("@/views/front/jsf35/operations/operations-center"),
    },
    {
        path: "/:project_id/scds/security-center",
        name: "project-scds-security-center",
        component: () => import("@/views/front/jsf35/operations/security-center"),
    },
    {
        path: "/:project_id/scds/service-center",
        name: "project-scds-service-center",
        component: () => import("@/views/front/jsf35/operations/service-center"),
    },
    {
        path: "/:project_id/scds/carbon-emission-report",
        name: "project-scds-carbon-emission-report",
        component: () => import("@/views/front/scds/subsystems/components/carbon-emission-report/index.vue"),
    },

    // {
    //   path: "/:project_id/jsf/operations-center",
    //   name: "project-jsf-operations-center",
    //   component: () => import("@/views/front/jsf35/operations/operations-center"),
    // },
    // {
    //   path: "/:project_id/jsf/security-center",
    //   name: "project-jsf-security-center",
    //   component: () => import("@/views/front/jsf35/operations/security-center"),
    // },
    // {
    //   path: "/:project_id/jsf/service-center",
    //   name: "project-jsf-service-center",
    //   component: () => import("@/views/front/jsf35/operations/service-center"),
    // },
];
