export default [
	//锦什坊项目主页
	{
		path: "/:project_id/jsf/home-page",
		name: "project-jsf-homepage",
		component: () => import("@/views/front/jsf35/homepage"),
	},
	//锦什坊项目子系统
	{
		path: "/:project_id/jsf/subsystems/:system_key",
		name: "project-jsf-subsystems",
		component: () => import("@/views/front/jsf35/subsystems"),
	},
	{
		path: "/:project_id/jsf/engineering-center",
		name: "project-jsf-engineering-center",
		component: () => import("@/views/front/jsf35/operations/engineering-center"),
	},
	{
		path: "/:project_id/jsf/operations-center",
		name: "project-jsf-operations-center",
		component: () => import("@/views/front/jsf35/operations/operations-center"),
	},
	{
		path: "/:project_id/jsf/security-center",
		name: "project-jsf-security-center",
		component: () => import("@/views/front/jsf35/operations/security-center"),
	},
	{
		path: "/:project_id/jsf/service-center",
		name: "project-jsf-service-center",
		component: () => import("@/views/front/jsf35/operations/service-center"),
	},
];
