<template>
	<div class="w-100 h-100">
		<b-embed :aspect="options.aspect || '16by9'" :src="src" :type="options.type || 'iframe'" allow-cross-origin allowfullscreen class="w-100 h-100" scrolling="no"></b-embed>
	</div>
</template>

<script>
	import { computed, reactive, toRefs } from "@vue/composition-api";
	import { new_nanoid } from "@/libs/utils/nanoId";

	export default {
		props: ["item"],
		setup(props) {
			const { item } = props;
			const event = reactive({
				src: computed(() => {
					let src = item.options.src;
					const unique_str = new_nanoid();
					if (src.indexOf("?") > -1) {
						src += "&noCache=" + unique_str;
					} else {
						src += "?noCache=" + unique_str;
					}
					console.log("src", src);
					return src;
				}),
				options: computed(() => {
					return item.options;
				}),
			});
			return {
				...toRefs(event),
			};
		},
	};
</script>
<style>
	.embed-responsive {
		height: 100%;
	}
</style>
