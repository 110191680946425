export default [
	//海境界二期
	{
		path: "/:project_id/hjj2/demo",
		name: "project-hjj2-demo",
		component: () => import("@/views/front/hjj2/demo"),
	},
	{
		path: "/:project_id/hjj2/demo1",
		name: "project-hjj2-demo1",
		component: () => import("@/views/front/hjj2/demo1"),
	},
	{
		path: "/:project_id/hjj2/demo2",
		name: "project-hjj2-demo2",
		component: () => import("@/views/front/hjj2/demo2"),
	},
	{
		path: "/:project_id/hjj2/demo3",
		name: "project-hjj2-demo3",
		component: () => import("@/views/front/hjj2/demo3"),
	},
	{
		path: "/:project_id/hjj2/demo4",
		name: "project-hjj2-demo4",
		component: () => import("@/views/front/hjj2/demo4"),
	},
]