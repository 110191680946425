import Vue from "vue";
// Notification
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

/**
 * 通用请求异常提醒
 * @param error http异常则传入error,其他提醒则为null
 * 1.不为null时为http异常，提醒为danger；2.为null时为其他消息提醒(success或warning)
 * @param title 提醒的标题：
 * 1.包含"成功"时为success;2.包含"提示"时为warning;3.包含"错误"时为danger;
 * @param text 提醒的内容:自定义内容
 */
// 张强编写的 showToast() 函数。
export function showToast(error = null, title = "", text = "") {
	const _title = title;
	const _text = text;
	if (error) {
		if (error.response) {
			console.log("error", error.response);
			const response = error.response;
			title = "API 错误 : " + response.statusText;
			let errorText = response.data;
			if (typeof errorText !== "string") {
				errorText = response.data.message || response.data.data.message;
			}
			if (errorText.length > 200) {
				errorText = errorText.substring(0, 200) + "...";
			}
			text = "( " + response.config.method.toUpperCase() + " ) " + response.config.url + " : " + errorText;
		} else {
			title = "错误 : ";
			text = error.message;
		}
	}
	let variant = "success";
	let icon = "AlertTriangleIcon";
	if (["成功", "通过"].find((key) => title.indexOf(key) > -1)) {
		variant = "success";
		icon = "CheckIcon";
	}
	if (["提示"].find((key) => title.indexOf(key) > -1)) {
		variant = "warning";
	}
	if (["失败", "错误", "异常"].find((key) => title.indexOf(key) > -1)) {
		variant = "danger";
		icon = "XCircleIcon";
	}


	Vue.$toast({
		component: ToastificationContent,
		props: {
			title: _title || title,
			text: _text || text,
			icon: icon,
			variant: variant,
		},
	}, {
		timeout: variant === "danger" ? 5000 : 3000,
	});
}

// 使用方法1: 程序员可以在各组件 Vue 文件中 使用 import showToast from '@/toast' 来引入下面的函数。

// 使用方法2: 程序员也可以直接在组件中使用 this.$showToast(), 或者在 setup() 中使用 instance.$showToast() 函数。
// 其中 instance 是通过 Vue 3.0 的 Composition API 提供的 getCurrentInstanc() 函数得到的当前 Vue 实例。
Vue.prototype.$showToast = showToast;
