import useJwt from "@/libs/auth/jwt/useJwt";

const axios = useJwt.axiosIns;

export const getStatisticsNumbers = (params) =>
  axios.get("/api/jsf35/statistics_numbers", { params });
export const getAlarms = (params) => axios.get("/api/jsf35/alarms", { params });
export const getAlarmsOptions = (params) =>
  axios.get("/api/jsf35/alarms-options", { params });

export const getEquipMeterCodeList = (params) =>
  axios.get("/api/jsf35/equip_meter_code_list", { params });
export const getEquipmentList = (params) =>
  axios.get("/api/jsf35/equipment", { params });
export const getEquipmentTree = (params) =>
  axios.get("/api/jsf35/equipment/tree", { params });
export const getEquipment = (equip_id) =>
  axios.get(`/api/jsf35/equipment/${equip_id}`);
export const updateEquipment = (data) =>
  axios.put(`/api/jsf35/equipment/${data.equip_id}`, data);
export const addEquipment = (data) => axios.post(`/api/jsf35/equipment`, data);
export const deleteEquipment = (equip_id) =>
  axios.delete(`/api/jsf35/equipment/${equip_id}`);
export const getServerOnlineStatus = (params) =>
  axios.get(`/api/jsf35/equipment/get-server-online-status`);

export const deleteParam = (param_id) =>
  axios.delete(`/api/jsf35/params/${param_id}`);

export const getDatasourceList = (params) =>
  axios.get("/api/panel/datasource", { params });
export const getTableList = (datasource_id) =>
  axios.get(`/api/panel/datasource/tables/${datasource_id}`);
export const getFieldList = (datasource_id, table) =>
  axios.get(`/api/panel/datasource/fields/${datasource_id}/${table}`);
export const getValueList = (datasource_id, table, field) =>
  axios.get(`/api/panel/datasource/values/${datasource_id}/${table}/${field}`);
export const getLatestValue = (params) =>
  axios.get(`/api/panel/datasource/latest-value`, { params });
//指令下发
export const updateCommand = (data) =>
  axios.post(`/api/jsf35/equipment/command`, data);
// 获取电梯设备实时数据
export const getCurrentLiftData = (data) =>
  axios.post(`/api/jsf35/system-current-data`, data);

//能耗查询
export const getEnergyFilters = (params) =>
  axios.get("/api/jsf35/energy/get-filters", { params });
export const getEnergyCompareDataList = (data) =>
  axios.post(`/api/jsf35/energy/get-energy-compare-data`, data);
export const getEnergyDataList = (params) =>
  axios.get(`/api/jsf35/energy/get-energy-data`, { params });
export const updateEnergyData = (data) =>
  axios.post(`/api/jsf35/energy/update-energy-data`, data);
export const getEnergyQuotas = (params) =>
  axios.get(`/api/jsf35/energy/get-energy-quotas`, { params });
export const setEnergyQuotas = (data) =>
  axios.post(`/api/jsf35/energy/set-energy-quotas`, data);
//火灾报警查询
export const getFireAlarmList = (params) =>
  axios.get("/api/jsf35/fire-alarm-list", { params });
export const clearFireAlarm = (data) =>
  axios.post(`/api/jsf35/clear-fire-alarm`, data);
export const submitFireAlarm = (data) =>
  axios.post(`/api/jsf35/submit-fire-alarm`, data);

//EasyMedia的接口
//获取已注册的列表
export const easyMediaList = (params) =>
  axios.get("/easy-media/list", { params });
//添加设备列表
export const easyMediaAdd = (params) =>
  axios.get("/easy-media/add", { params });
//开启推流
export const easyMediaStart = (params) =>
  axios.get("/easy-media/start", { params });
//关闭推流
export const easyMediaStop = (params) =>
  axios.get("/easy-media/stop", { params });

//获取用户权限
export const getUserPermission = (params) =>
  axios.get("/api/jsf35/user-permission", { params });
