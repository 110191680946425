<template>
  <div class="w-100 h-100 position-relative">
    <!-- <h5 class="text-center text-danger" style="margin-top: 10%">关系图-桑基、和弦图</h5> -->
    <div id="relationView" style="width: 90%; height: 90%"></div>
  </div>
</template>

<script>
import { Sankey,Chord } from "@antv/g2plot";
import { onMounted } from "@vue/composition-api";
export default {
  name: "G2RelationView",
  setup() {
    const DATA = [
      { source: "首次打开", target: "首页 UV", value: 160 },
      { source: "结果页", target: "首页 UV", value: 40 },
      { source: "验证页", target: "首页 UV", value: 10 },
      { source: "我的", target: "首页 UV", value: 10 },
      { source: "朋友", target: "首页 UV", value: 8 },
      { source: "其他来源", target: "首页 UV", value: 27 },
      { source: "首页 UV", target: "理财", value: 30 },
      { source: "首页 UV", target: "扫一扫", value: 40 },
      { source: "首页 UV", target: "服务", value: 35 },
      { source: "首页 UV", target: "蚂蚁森林", value: 25 },
      { source: "首页 UV", target: "跳失", value: 10 },
      { source: "首页 UV", target: "借呗", value: 30 },
      { source: "首页 UV", target: "花呗", value: 40 },
      { source: "首页 UV", target: "其他流向", value: 45 },
    ];

    const initSankey = () => {
      const sankey = new Sankey("relationView", {
        data: DATA,
        sourceField: "source",
        targetField: "target",
        weightField: "value",
        nodeWidthRatio: 0.008,
        nodePaddingRatio: 0.03,
      });

      sankey.render();
    };

	onMounted(()=>{
		initSankey()
	})


  },
};
</script>
