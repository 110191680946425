import useJwt from "@/libs/auth/jwt/useJwt";

const axios = useJwt.axiosIns;

export const getProjectList = (params) => axios.get("/api/project/list/", params);
export const getProjectDetail = (id) => axios.get(`/api/project/info/${id}/`);
export const getProjectSystemDetail = (id) => axios.get(`/api/project/system_info/${id}/`);

export const updateProjectInfo = (data) => axios.patch(`/api/project/edit/${data.project_id}/`, data);
export const addProjectInfo = (data) => axios.post(`/api/project/add/`, data);
export const deleteProject = (id) => axios.delete(`/api/project/delete/${id}/`);

export const getProjectEnergy = (id) => axios.get(`/api/project/energy/${id}/`);
export const getProjectParams = (id) => axios.get(`/api/project/params/${id}/`);
export const getProjectOperation = (id) => axios.get(`/api/project/operation/${id}/`);
export const getProjectOptimization = (id) => axios.get(`/api/project/optimization/${id}/`);

export const getAgentLine = (id) => axios.get(`/api/diagnosis/charts/${id}`);
export const getAgentPie = (id) => axios.get(`/api/diagnosis/pie/${id}`);

export const getImmediacyExecute = (data) => axios.post(`/api/diagnosis/immediacy_execute/`,data)
export const getCheckStatu = (id) => axios.get(`/api/diagnosis/immediacy_check_status/${id}/`)
export const getLog = (id) => axios.get(`/api/diagnosis/log/${id}/`)
export const getIndexTopInfo = () => axios.get(`/api/diagnosis/index_top/`)
export const getImmediacyResult = (params) => axios.get("/api/diagnosis/immediacy_result", { params });
