// 判断两个对象是否相等
export function isObjectChanged(source, comparison) {
	const iterable = (data) => ["Object", "Array"].includes(getDataType(data));

	if (getDataType(source) !== getDataType(comparison)) {
		return true;
	}

	const sourceKeys = Object.keys(source);

	const comparisonKeys = Object.keys({
		...source,
		...comparison,
	});

	if (sourceKeys.length !== comparisonKeys.length) {
		return true;
	}

	return comparisonKeys.some((key) => {
		if (iterable(source[key])) {
			return isObjectChanged(source[key], comparison[key]);
		} else {
			return source[key] !== comparison[key];
		}
	});
}

// 判断数据类型
function getDataType(data) {
	const temp = Object.prototype.toString.call(data);
	const type = temp.match(/\b\w+\b/g);
	return type.length < 2 ? "Undefined" : type[1];
}
