<template>
	<div>embed</div>
</template>

<script>
	import Ripple from "vue-ripple-directive";
	import { reactive, toRefs } from "@vue/composition-api";

	export default {
		name: "Settings",
		directives: {
			Ripple,
		},
		props: ["item"],
		setup(props) {
			const { item } = props;
			const event = reactive({});
			// watch(() => event.selectedFile, (file) => {
			// 	if (!!file) {
			// 		uploadFile(file)
			// 	}
			// })
			//更新store的数据
			// const update_store_options=()=>{
			// 	let options = store.state['panel-group']['panel-modal']['options']
			// 	options.list = event.list
			// 	store.commit('panel-group/UPDATE_PANEL_MODAL_OPTIONS', { ...options })
			// }
			return {
				...toRefs(event),
			};
		},
	};
</script>
