<template>
	<div class="w-100 h-100 position-relative">
		<h5 class="text-center text-danger" style="margin-top: 10%">未配置的组件</h5>
	</div>
</template>

<script>
	export default {
		name: "BlankView",
	};
</script>
