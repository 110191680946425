import store from "@/store";
import { showToast } from "@/libs/utils/showToast";
import { routerParams } from "@/libs/utils/routerParams";

export async function refreshProject(project_id) {
	const project_selected = project_id || routerParams("project_id");
	if (!!project_selected) {
		store
			.dispatch("projects/fetchProject", project_selected)
			.then((response) => {
				const project = response.data;
				localStorage.setItem("project_id", project.project_id);
				localStorage.setItem("project-selected", JSON.stringify(project));
				store.commit("projects/UPDATE_PROJECT_SELECTED", project);
			})
			.catch((error) => {
				showToast(error);
			});
	}
}
