import Vue from "vue";

// chenxi 20210724
// 这里只引入全局都需要使用的 BootstrapVue 组件。在每个 Vue 文件中，也只引入该 Vue 文件中必须使用的组件。
// 减少打包后的文件体积(Tree Shaking)。
import {
  BootstrapVue,
  IconsPlugin,
  ModalPlugin,
  ToastPlugin,
} from "bootstrap-vue";
// chenxi 20210724
// Vue 3.0 的新功能 Composition API, 引入到 Vue 2.6 中使用。
import VueCompositionAPI from "@vue/composition-api";
// i18n 多语言支持
import i18n from "@/libs/i18n";

// Vue-Router
// eslint-disable-next-line camelcase
import auto_import_panel_components from "@/views/panel-group/components";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

import router from "./router";

// Vuex
import store from "./store";

// 项目的入口 Vue 文件。
import App from "./App.vue";

// chenxi 20210724
// 下面这行代码，实际上只引用了 FeatherIcon, 没有其他作用。由于 vuexy_dir 未来会被删除，因此把 FeatherIcon 的引用合并至本文件，
// 注释掉下面这行代码。
// import './vuexy_dir/global-components'
// 原作者在 vue.config.js 中定义了一个路径 alias, 将 @/libs/axios 缩短为 @axios. 但是我觉得这样并不方便，因为程序员很习惯使用
// @ 代表 src, 但是不会记住在某个特定的项目里面， @axios 具体代表什么。反而不如直接使用 @/libs/axios 更清楚。
// 因此注释掉下面这行代码。
// import '@axios'
import "@/libs/axios";

// 前端用于权限控制的组件，使用的是 @casl/vue. 请查看 CASL 的官方文档。
import "@/libs/acl";

// portal-vue 用于将一个组件，"传送"至另一个组件中渲染。例如，一个写在组件内的弹出式对话框，需要渲染至 <body> 元素的顶部或者底部，
// 即可使用 <portal>. portal 在 Vue 3.0 版本中将原生支持。在 Vue 2.6 版本中，需要单独引入。
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";
// import "@/assets/scss/project-jsf/jsf.scss";
// 张强编写的批量自动引入panel-group插件的自定义组件
// 正式项目，不再需要使用假数据。注释掉下面这行代码
// Axios Mock Adapter
import "@/assets/iconfont/iconfont.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(IconsPlugin);
Vue.use(BootstrapVue);

Vue.use(VueCompositionAPI);
Vue.component(FeatherIcon.name, FeatherIcon);
// feature icon 的 css
require("@/@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// vuexy 框架的核心 scss
require("@/@core/scss/core.scss");

// 程序员自定义 scss, 用来覆盖 vuexy 框架的缺省 scss.
require("@/assets/scss/style.scss");

auto_import_panel_components();

// 打包发布的版本 (npm run build), 不在控制台显示 Vue 的提示语句。这些提示语句只对程序员的开发有用。
Vue.config.silent = false;
Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
